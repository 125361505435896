@tailwind base;
@tailwind components;
@tailwind utilities;

/* #masonry {
  column-count: 3;
  font-size: 0;
}
#masonry .post {
  display: inline-block;
}
#masonry .post-image {
  display: block;
} */
/* @layer components {
  .hero {
    @apply w-full text-[#333];
  }
}

.title {
  @apply w-full pt-20 m-0;
  font-size: 48px;
}

.title,
.description {
  @apply text-center;
} */

#masonry .post {
  page-break-inside: avoid;
  min-height: 256px;
}
